<template>
  <div class="continer">
    <div class="header">
      <h1 class="title">真假财务</h1>
    </div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <!--查询栏-->
        <el-row :gutter="10">
          <el-col :span="6" class="cxname">
            <el-input v-model="name" size="medium" placeholder="请输入姓名"></el-input>
          </el-col>
          <el-col :span="3">
            <el-button type="primary" size="medium" icon="el-icon-search" @click="search">查询</el-button>
          </el-col>
        </el-row>
      </div>
      <div v-if="add">
        <!--添加凭证和评价-->
        <div class="buts">
          <div class="scpz">
            <!-- <el-button type="primary" size="medium" @click="upload">上传凭证({{ process }})</el-button> -->
            <el-upload :disabled="true" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/"
              :on-remove="handleRemove" multiple :limit="5" :on-change="handleChange">
              <el-button size="medium" type="primary">上传凭证({{ fileList.length }}/5)</el-button>
            </el-upload>
          </div>
          <div class="wdpj">
            <el-button type="primary" size="medium" @click="showMyEvaluates">我的评价</el-button>
          </div>
        </div>

        <!--投票-->
        <div class="contenr">
          <div class="toupiao">
            <el-card shadow="hover">
              <div slot="header">
                <el-row>
                  <el-col :span="20">
                    <h1 class="title">姓名 {{ name }}</h1>
                  </el-col>
                  <el-col :span="3">
                    <h1>评价</h1>
                  </el-col>
                </el-row>
              </div>
              <!--单选投票类型-->
              <el-row class="radio-type" v-for="(it, index) in typeList" :key="index">
                <el-col :span="7">
                  <el-radio :ref="'Ref' + index" v-model="type" :label="it.type" :disabled="!isAdd">{{ it.type
                    }}</el-radio>
                </el-col>
                <el-col :span="17">
                  <el-progress :percentage="it.percentage"></el-progress>
                </el-col>
              </el-row>
              <el-button style="margin-top: 20px;" type="primary" size="medium" @click="submit"
                :disabled="!isAdd">确认投票</el-button>
            </el-card>
          </div>
          <div class="jilu">
            <el-card shadow="hover">
              <div slot="header">
                <h1 class="title">评价记录</h1>
              </div>
              <el-empty v-if="info.evaluates.length == 0" description="描述文字"></el-empty>
              <el-tag class="tag-row" v-else v-for="(it, index) in info.evaluates" :key="index" type="info"
                effect="plain">
                {{ it.type }}{{ it.content?' | '+it.content:'' }}
              </el-tag>
            </el-card>
          </div>
        </div>
      </div>
    </el-card>
    <!--填写评价内容弹窗-->
    <el-dialog :visible.sync="dialogVisible" :center="true" :append-to-body="true" width="350px" title="填写评价">
      <el-form :model="form">
        <el-form-item label="评价内容">
          <el-input type="textarea" maxlength="50" show-word-limit v-model="form.content" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveEvaluate">确 定</el-button>
      </div>
    </el-dialog>
    <!--我的评价-->
    <el-dialog title="我的评价" :append-to-body="true" :visible.sync="myEvaluateVisible" width="80%">
      <el-table :data="myEvaluates">
        <el-table-column property="person" label="被投人"></el-table-column>
        <el-table-column property="type" label="投票"></el-table-column>
        <el-table-column property="content" label="内容"></el-table-column>
      </el-table>
    </el-dialog>

  </div>
</template>
<script>
import service from '@/api/common'
export default {
  data() {
    return {
      prefix: 'api/really',
      evaluatePrefix: 'api/really/evaluate',
      name: null,
      add: false,
      isAdd: true,
      info: { evaluates: [] },
      typeList: [{ type: '真财务', count: 0, percentage: 0 }, { type: '假财务', count: 0, percentage: 0 },
      { type: '操作手', count: 0, percentage: 0 }, { type: '撸子', count: 0, percentage: 0 },
      { type: '刷子', count: 0, percentage: 0 }],
      myEvaluates: [],
      total: 0,
      type: null,
      selectedType: null,
      fileList: [],
      dialogVisible: false,
      myEvaluateVisible: false,
      form: {}
    }
  },
  methods: {
    // 查询对象
    async search() {
      let detail = await service.get(this.prefix + '/detailByName', { name: this.name })
      if (detail) {
        this.info = detail
        this.add = true
        // 计算投票进度
        this.percentage()
        if (detail.myEvaluate) { // 已评价就禁止操作
          this.isAdd = false
        }else{
          this.isAdd = true
        }
        // 解决radio报错
        this.$nextTick(() => {
          this.typeList.forEach((item, index) => {
            this.$refs['Ref' + index][0].$refs.radio.removeAttribute("aria-hidden");
          })
        })
      } else {
        this.saveInfo()
      }
    },
    saveInfo() {
      this.$confirm('没有查到此人，是否添加此人?', '提示', {
        confirmButtonText: '添加评价',
        showCancelButton: false,
        showClose: false,
        type: 'warning',
        center: true
      }).then(() => {
        service.add(this.prefix, { name: this.name }).then((res) => {
          this.add = true
          this.search()
        })
      }).catch(() => {
      })
    },
    // 评价对象
    evaluate() {
      this.dialogVisible = true
      this.form = {}
    },
    async saveEvaluate() {
      let yes = await this.$confirm('确认提交投票?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (yes) {
        let data = { ...this.form, reallyId: this.info.id, type: this.type }
        let res = await service.add(this.evaluatePrefix, data)
        this.dialogVisible = false
        this.search()
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }
    },
    percentage() {
      let total = 0

      // 重置数据
      this.typeList.map(it => {
        it.count = 0
        it.percentage = 0
      })
      this.typeList.map(it => {
        let temp = this.info.evaluates.filter(evaluate => evaluate.type === it.type)
        if (temp != null && temp.length > 0) {
          it.count+=temp.length
          total+=temp.length
        }
      })

      this.typeList.map(it => {
        if(total!=0){
            it.percentage = it.count == 0 ? 0 : total == 0 ? 100 : Math.round(it.count * 100 / total)
          }
      })
      console.log(223,this.typeList)
    },
    // 上传凭证开始
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    handleRemove(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    // 上传凭证结束
    submit() {
      if (this.type == null) {
        this.$message({
          type: 'warning',
          message: '至少投票选择一项！'
        })
        return
      }
      this.evaluate()
    },
    showMyEvaluates() {
      this.myEvaluateVisible = true
      this.getMyEvaluates()
    },
    // 我的评价数据
    async getMyEvaluates() {
      let res = await service.list(this.evaluatePrefix)
      this.myEvaluates = res
    }
  }
}

</script>
<style lang="scss" scoped>
.header {
  margin-bottom: 20px;
}

.continer {
  width: 100%;
  height: 100%;
}

.title {
  font-weight: bold;
  color: black;
}

.box-card {
  width: 100%;
  height: 100%;
}

.oprate {
  margin: 0 0 20px 0px;
}

.radio-type {
  margin: 15px 15px 15px 0px;
}

.el-card__body {
  padding: 20px;
  height: 393px;
}

.tag-row {
  margin: 5px;
}

.buts{
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 15px;
}

.scpz{
  margin-right: 10px;
}

.contenr{
  display: flex;
  flex-wrap: nowrap;
}

@media screen and (min-width: 1440px) {
  .cxname{
    width: 25%;
  }
  .toupiao{
    width: 45%;
    margin-right: 20px;
  }
  .jilu{
    width: 45%;
  }
}
@media screen and (max-width: 1440px) {
  .cxname{
    width: 25%;
  }
  .toupiao{
    width: 45%;
    margin-right: 20px;
  }
  .jilu{
    width: 45%;
  }
}
@media screen and (max-width: 1180px) {
  .cxname{
    width: 35%;
  }
  .contenr{
    display: block;
  }
  .toupiao{
    width: 100%;
    margin-bottom: 20px;
  }
  .jilu{
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .cxname{
    width: 45%;
  }
}
@media screen and (max-width: 480px) {
  .cxname{
    width: 55%;
  }
}
.upload-demo{
  display: inline-block;
}
</style>